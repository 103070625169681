import React, { useState } from 'react';
import './research.css';

const Research = () => {
  const [activeBox, setActiveBox] = useState<number | null>(null);

  const toggleBox = (index: number) => {
    setActiveBox(activeBox === index ? null : index);
  };

  const boxContent = [
    {
      title: "1. To form a habit, it needs to be easier than thinking.",
      content: "Nir Eyal in <i>Hooked</i> writes how reducing thinking required of a user makes it more likely that a desired behavior is performed. This is why we provide many different prompts on Candid – so users do not have to stress about what content to post. Of course, we also have space for users to post any other content they want to too."
    },
    {
      title: "2. Gamification helps us form habits.",
      content: "One major reason Duolingo is so successful is that it makes language learning easy and enjoyable through gamification. Drawing on this, we introduce a score and streak on Candid, so as to encourage daily journaling as a habit."
    },
    {
      title: "3. Making things social help us form habits",
      content: "Exercise circles that having a running or workout buddy holds us more accountable to do what is good for us, and make us much more likely to keep the habot. Similarly, people find that doing something with a sense of community or team is more motivating. Strava embedded a social aspect into their product to make working out more enjoyable, and we do the same."
    },
    {
      title: "4. Large network sizes create unhealthy incentives",
      content: "The more friends you have on a platform, the harder it is to be vulnerable. Too many friends means you’re more incentivized to fit into broader social norms or overshare to attract attention: this is the simple truth of how our social brains work. At the end of the day, we want to be loved for who we are and who we’re trying to be, and Candid facilitates us doing just that by keeping our network of friends small."
    },
    {
      title: "5. Reframing the way you see your life helps you.",
      content: "A recent meta-study of the effectiveness of CBT found it to equal or improve upon the performance of medication for even the most difficult mental health illnesses. We’re not claiming to offer CBT, but we’re trying to strike the same chord. We want Candid to be a gentle but truthful companion, helping you realize alternative ways to see your life. We’re already seeing this happen – people have begun using AI chatbots like ChatGPT as therapists – and the more you use Candid, the better it can identify themes to improve your experience of life."
    }
  ];
  

  return (
    <div className="section__padding fc">
      <h1 className="about__title">Our Research</h1>
      <p style={{color: '#eee', fontSize: '1rem', marginBottom: 12}}>A lot of thought has gone into building Candid. We’ve spoken to and are advised by world-renowned experts in psychology like Dr. Anna Lembke and Dr. Johannes Eichstaedt. We’ll keep our findings short and explain how each of these has impacted our design.</p>

      {boxContent.map((box, index) => (
        <div
          className={`research-box ${activeBox === index ? 'active' : ''}`}
          key={index}
          onClick={() => toggleBox(index)}
        >
          <h2>{box.title}<span className={`caret ${activeBox === index ? 'caret-rotate' : ''}`}>⌄</span></h2>
          <p dangerouslySetInnerHTML={{ __html: box.content }}></p>
        </div>
      ))}

      <h2 className="works-consulted">Works consulted</h2>
      <a style={{color: '#eee', fontSize: 14, marginTop: 6}} href="https://www.nirandfar.com/hooked/">1. <u>Hooked</u></a>
      <a style={{color: '#eee', fontSize: 14, marginTop: 6}} href="https://www.linkedin.com/pulse/duolingo-mastering-art-gamification-learning-rashika-ahuja/">2. <u>Duolingo: Gamification Learning</u></a>
      <a style={{color: '#eee', fontSize: 14, marginTop: 6}} href="https://www.aarp.org/health/healthy-living/info-2019/exercising-with-a-partner.html">3. <u>Exercising With a Partner</u></a>
      <a style={{color: '#eee', fontSize: 14, marginTop: 6}} href="https://www.psychologicalscience.org/news/minds-business/just-feeling-like-part-of-a-team-increases-motivation-on-challenging-tasks.html">4. <u>Psycholgical Science: Being Part of a Team Increases Motivation</u></a>
      <a style={{color: '#eee', fontSize: 14, marginTop: 6}} href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3584580/">5. <u>The Efficacy of Cognitive Behavioral Therapy</u></a>
      <a style={{color: '#eee', fontSize: 14, marginTop: 6}} href="https://www.npr.org/sections/health-shots/2023/01/19/1147081115/therapy-by-chatbot-the-promise-and-challenges-in-using-ai-for-mental-health">6. <u>The Promises and Challenges in using AI for Mental Health</u></a>
    </div>
  );
};

export default Research;
