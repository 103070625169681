import React from 'react'
import './footer.css'
import IG from '../../assets/ig.svg'
import TikTok from '../../assets/tiktok.svg'
type Props = {}


const Footer = (props: Props) => {
  return (
    <div className="footer-container fc">

        <div className="footer-content-container fr sb">
            <div className="footer__left-container fr">
                <div className="footer__links-col fc">
                    <h1>Company</h1>
                    <a href="/about">About us</a>
                    <a href="/research">Research</a>
                    <a href="https://medium.com/@candidsocial.app">Blog</a>
                </div>
                <div className="footer__links-col fc">
                    <h1>Legal</h1>
                    <a href="https://matthewwirtz-my.sharepoint.com/:b:/g/personal/mwirtz_candidsocial_app/EfpfDnCU2ppLvqFARPJWaIQBdezkCEh1P76mKVAXaU4WaA?e=h5DhPh" download>Privacy</a>
                    <a href="https://matthewwirtz-my.sharepoint.com/:b:/g/personal/mwirtz_candidsocial_app/EaUHs2mXlSBJiHVolwROBJMBDc4iOV5DNwlJyH8QFIc-kg?e=c2Wpzj" download>Terms</a>
                </div>
            </div>
            {/* <div className="footer__right-container fc">
                <a style={{color: 'white', fontSize: 12, fontWeight: '200'}}>Help</a>
            </div> */}
        </div>
        <div className="footer-div-line" />
        <div className="footer__bottom-container fr sb">
            <div className="footer__bottom-left-container">
                <p>Copyright 2023</p>
            </div>
            <div className="fr ac footer__icons-row">
                <a href="https://www.instagram.com/candidsocial.app/?hl=en">
                    <img src={IG} alt="ig-icon" />
                </a>
                <a href="https://www.tiktok.com/@candidsocial.app"> 
                    <img src={TikTok} alt="tiktok-icon" />   
                </a>
            </div>
        </div>
    </div>
  )
}

export default Footer