import React from 'react'
import './privacy.css'
type Props = {}

const Privacy = (props: Props) => {
  return (
    <div className="section__padding fc" style={{height: '80vh'}}>
    <h1 style={{color: 'white', textAlign: 'center'}}>Privacy Policy</h1>
    <a href='https://matthewwirtz-my.sharepoint.com/:b:/g/personal/mwirtz_candidsocial_app/EfpfDnCU2ppLvqFARPJWaIQBdezkCEh1P76mKVAXaU4WaA?e=h5DhPh' style={{color: '#eee', marginTop: 12, textAlign: 'center'}}>Click here to download our privacy policy</a>
  </div>
  )
}

export default Privacy