import { createContext, useContext, useState } from "react";
const NavbarContext = createContext(null);
export const useNavbarContext = () => useContext(NavbarContext)

export const NavbarContextProvider = ({ children }) => {
    const [activePage, setActivePage] = useState("")
    
    const value = {
        activePage, setActivePage
    }
    return (
        <NavbarContext.Provider value={value}>{children}</NavbarContext.Provider>
    )
}