import React, { useEffect, useRef } from 'react'
import './about.css'
import IMG from '../../assets/feed-background.png'
import AboutGraphic from '../../assets/aboutGraphic.svg'
type Props = {}

const About = (props: Props) => {

  return (
    <div className="fc">
      <div className="section__padding about__top-container" style={{marginTop: 32, marginBottom: 48}}>
          <div className="fc" style={{marginRight: 24}}>
            <h1 style={{fontSize: 48, color: '#fff', marginBottom: 24, fontWeight: '800'}}>Rediscover Social</h1>
            <p style={{color: 'white', fontSize: 24}}><i>A new way to prioritize your wellbeing.</i> This is Candid - the innovative app designed to turn self-reflection and mindfulness into a social habit. Candid empowers you to understand yourself better while staying connected with your closest friends.</p>
          </div>
          <img src={AboutGraphic} alt="aboutGraphic" style={{maxWidth: 500, width: '100%', marginTop: 48}}/>
      </div>
      <div className="fr">
          {/* <div className="about__left-col__container ac jc">
              <img src={IMG} alt='feed-background' />
          </div> */}
          <div className="about__right-col__container ac jc" style={{backgroundColor: 'white'}}>
        <h1 className="about__title" style={{color: 'white', marginBottom: 16}}>Hi, we're candid.</h1>
        <div className="paragraph__container section__padding">
                <h1 style={{marginBottom: 24, fontSize: 48}}>Our Story</h1>
                <p className="about__description" style={{color: '#000', fontSize: 20, marginBottom: 32}}>Candid was born from a very personal starting point: our mental health. We’re a collection of college students frustrated by the bombardment of our wellbeing on many fronts: accessing resources is hard (and it’s only going to get harder), and the digital products we engage with do not retain us. Beyond this, college is a place where it’s natural to feel overwhelmed, and sink hard because of it. In recent years, rates of depression in college students have doubled to 40%.</p>
                <p className="about__description" style={{color: '#000', fontSize: 20, marginBottom: 32}}>At the same time, the social platforms we’ve traditionally engaged with are rapidly transitioning to become entertainment platforms where it’s increasingly hard to see our friends and get a good grasp on their everyday lives. This is because their end goal is to maximize our dopamine, the excitement and rush we feel when we are seen and heard, and becoming entertainment platforms better serves this platform.</p>
                <p className="about__description" style={{color: '#000', fontSize: 20, marginBottom: 32}}>And yet, we believe that connection will soon be as compelling. The world is moving to record rates of loneliness, but also towards a place where being vulnerable, expressive and, well, candid, is accepted and normal. At Candid, we want it to feel as good to be deeply and consistently understood by your closest friends as it is to reach a million strangers.</p>
                <p className="about__description" style={{color: '#000', fontSize: 20, marginBottom: 32}}>The premise behind Candid is that making habits social, make them work. Candid seeks to connect over honest videos about your everyday life and thoughts: we let you decide which of these you want to share, and which to keep private. Our hope is that you start using Candid as a social media and eventually like a journal too! Best of all, we turn the content you produce into meaningful summaries, memories, and tools that help you understand yourself a little better, and feel like you’re on top of your life and growth. </p>
              </div>
                <a href="https://apps.apple.com/us/app/candid-rediscover-social/id6447771018">
                  <button className="button-try-us-out">
                    <a>Try it out!</a>
                  </button>
                </a>
             </div>
      </div>
    </div>
  )
}

export default About

// import React, { useEffect, useRef, useState } from 'react';
// import './about.css';
// import IMG from '../../assets/feed-background.png';

// type Props = {}

// const About = (props: Props) => {
//   const TEXT = [
//     'Candid was born from a very personal starting point: our mental health. We’re a collection of college students frustrated by the bombardment of our wellbeing on many fronts: accessing resources is hard (and it’s only going to get harder), and the digital products we engage with do not retain us. Beyond this, college is a place where it’s natural to feel overwhelmed, and sink hard because of it. In recent years, rates of depression in college students have doubled to 40%.',
//     'At the same time, the social platforms we’ve traditionally engaged with are rapidly transitioning to become entertainment platforms where it’s increasingly hard to see our friends and get a good grasp on their everyday lives. This is because their end goal is to maximize our dopamine, the excitement and rush we feel when we are seen and heard, and becoming entertainment platforms better serves this platform.',
//   'And yet, we believe that connection will soon be as compelling. The world is moving to record rates of loneliness, but also towards a place where being vulnerable, expressive and, well, candid, is accepted and normal. At Candid, we want it to feel as good to be deeply and consistently understood by your closest friends as it is to reach a million strangers.',
//   'The premise behind Candid is that making habits social, make them work. Candid seeks to connect over honest videos about your everyday life and thoughts: we let you decide which of these you want to share, and which to keep private. Our hope is that you start using Candid as a social media and eventually like a journal too! Best of all, we turn the content you produce into meaningful summaries, memories, and tools that help you understand yourself a little better, and feel like you’re on top of your life and growth.'
//   ]
//   const [currentPara, setCurrentPara] = useState(-1);
//   const paraRefs = useRef([]);
//   const numParas = 4; // Total number of paragraphs
//   const scrollContainerRef = useRef(null);
//   const [showProgressBar, setShowProgressBar] = useState(false);
//   useEffect(() => {
//     const handleScroll = () => {
//       if (window.scrollY > window.innerHeight) {
//         setShowProgressBar(false);
//       } else {
//         setShowProgressBar(true);
//       }
//     };

//     window.addEventListener('scroll', handleScroll);

//     // IntersectionObserver code
//     const options = {
//       root: null,
//       rootMargin: '0px',
//       threshold: 0.5
//     };
//     const observer = new IntersectionObserver(entries => {
//       entries.forEach(entry => {
//         if (entry.isIntersecting) {
//           setCurrentPara(parseInt(entry.target.id));
//         }
//       });
//     }, options);
    
//     paraRefs.current.forEach((ref, index) => {
//       observer.observe(ref);
//     });

//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//       paraRefs.current.forEach((ref, index) => {
//         observer.unobserve(ref);
//       });
//     };
//   }, [paraRefs]);

//   const screenHeight = window.innerHeight;
//   const screenWidth = window.innerWidth;
//   const paragraphContainerStyle = {
//     marginBottom: screenHeight,
//   };
//   const paragraphTextStyle = {
//     color: '#eee',
//     fontSize: 30,
//   };

//   const scrollContainerStyle = {
//     height: screenHeight,
//     overflowY: 'scroll',
//     paddingRight: 200,
//     paddingLeft: 200
//   }

//   return (
//     <div className="fc ac">
//       <h1 className="about__title">Hi, we're candid.</h1>
//       <div className="fc" style={scrollContainerStyle}>
//         {showProgressBar && 
//           <div className="dot-progress-bar">
//             {Array.from({ length: numParas }).map((_, index) => (
//               <div key={index} className={currentPara === index ? 'dot-active' : 'dot-inactive'}></div>
//             ))}
//           </div>}
//         {TEXT.map((para, index) => (
//           <div id={index.toString()} ref={ref => paraRefs.current[index] = ref} key={index} style={paragraphContainerStyle}>
//             <p className="about__description" style={paragraphTextStyle}>{para}</p>
//           </div>
//         ))}
//       </div>
//       <a href="https://apps.apple.com/us/app/candid-rediscover-social/id6447771018">
//         <button className="button-try-us-out">
//           <a>Try it out!</a>
//         </button>
//       </a>
//     </div>
//   );
// };

// export default About;
