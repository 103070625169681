import React from 'react'
import './terms.css'

type Props = {}

const Terms = (props: Props) => {
  return (
    <div className="section__padding fc" style={{height: '80vh'}}>
      <h1 style={{color: 'white', textAlign: 'center'}}>Terms and Conditions</h1>
      <a href='https://matthewwirtz-my.sharepoint.com/:b:/g/personal/mwirtz_candidsocial_app/EaUHs2mXlSBJiHVolwROBJMBDc4iOV5DNwlJyH8QFIc-kg?e=c2Wpzj' style={{color: '#eee', marginTop: 12, textAlign: 'center'}}>Click here to download our terms and conditions</a>
    </div>
  )
}

export default Terms