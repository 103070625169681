import React, { useState } from 'react';
import './navbar.css';
import { useNavbarContext } from '../../contexts/NavbarContext';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const Navbar = () => {
    const [isActive, setActive] = useState(false);
    const [activeDisplay, setActiveDisplay] = useState("");
    const [loading, setLoading] = useState(true);
    const { activePage, setActivePage } = useNavbarContext();

    const toggleClass = () => {
        setActive(!isActive);
    }

    const delayInMilliseconds = 500;
    setTimeout(() => {
        setLoading(false);
    }, delayInMilliseconds);

    const links = [
        { text: 'About us', link: '/about' },
        { text: 'Research', link: '/research' },
        { text: 'Blog', link: 'https://medium.com/@candidsocial.app' },
    ];

    const navigate = useNavigate(); // Use the useNavigate hook

    const handleNavigate = (link) => {
        if (link[0] === '/') {
            navigate(link);
        } else {
            window.open(link, '_blank');
        }
    }
    return (
        <div className="candid__navbar">
            <div className="candid__navbar-content">
                <div>
                    <a style={{ color: 'white', fontWeight: 'bold' }} href="/" onClick={() => setActivePage("Home")}>
                        {activePage === "Home" ? "" : "candid"}
                    </a>
                </div>
                {!loading && (
                    <div className="candid__navbar-menu">
                        {links.map((item, index) => (
                            <button
                                className="candid__navbar-link"
                                key={index}
                                onClick={() => {
                                    setActivePage(item.text);
                                    handleNavigate(item.link); // Use the navigate function
                                }}
                                style={{ background: 'none', border: 'none' }}
                            >
                                <a
                                    onMouseEnter={() => {
                                        setActiveDisplay(item.text);
                                    }}
                                    onMouseLeave={() => setActiveDisplay("")}
                                >
                                    <h1>{item.text}</h1>
                                </a>
                                {activeDisplay === item.text && <hr className="candid__navbar-link-line" />}
                            </button>
                        ))}
                    </div>
                )}
                <div className="candid__navbar-hamburger">
                    <button onClick={toggleClass} id="candid__navbar-hamburger" className={isActive ? "is-active" : null}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                    <div id="candid__navbar-overlay" className={isActive ? "is-active" : null}>
                        {links.map((item, index) => (
                            <>
                                <a
                                    className={isActive ? "is-active" : null}
                                    onMouseEnter={() => {
                                        setActiveDisplay(item.text + "-overlay");
                                    }}
                                    onClick={() => {
                                        setActivePage(item.text);
                                        handleNavigate(item.link); // Use the navigate function
                                        setActive(false)
                                    }}
                                    
                                >
                                    {item.text}
                                </a>
                                <span className={isActive ? "is-active" : null}></span>
                            </>
                        ))}
                    </div>
                </div>
            </div>
            <hr className="candid__navbar-line" />
        </div>
    );
};

export default Navbar;
