import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { About, Footer, Hero, Navbar, Privacy, Research, Terms } from './containers';

import defaultCursor from './assets/cursor.svg'; // Import the SVG
import hoverCursor from './assets/cursorFocused.svg'; // Import the SVG
import { NavbarContextProvider } from './contexts/NavbarContext';

function App() {

  // THIS HANDLES THE BUTTERFLY CURSOR
  useEffect(() => {
    const setCustomCursor = () => {
      // Set the default cursor
      document.body.style.cursor = `url(${defaultCursor}), auto`;
  
      // Change the cursor on hover for specific elements (e.g., buttons and anchor tags)
      const hoverElements = document.querySelectorAll('button, a');
      hoverElements.forEach((el) => {
        el.style.cursor = `url(${hoverCursor}), pointer`;
      });
    };
  
    // Initially set the cursor
    setCustomCursor();
  
    // Observe changes in the DOM to update the cursor if necessary
    const observer = new MutationObserver(setCustomCursor);
    observer.observe(document.body, { childList: true, subtree: true });
  
    // You might want to reset the cursor when the component unmounts
    return () => {
      document.body.style.cursor = 'auto';
      observer.disconnect();
    };
  }, []);


  return (
    <NavbarContextProvider>
      <Router>
        <Routes>
            <Route path="/" element={<div>
              <div className="gradient__bg">
                <Navbar />
                <Hero />
              </div>
              <Footer />
            </div>} />
            <Route path="/about" element={<div>
              <div className="gradient__bg">
                <Navbar />
                <About />
              </div>
              <Footer />
            </div>} />
            <Route path="/research" element={<div>
              <div className="gradient__bg">
                <Navbar />
                <Research />
              </div>
              <Footer />
            </div>} />
            <Route path="/terms" element={<div>
              <div className="gradient__bg">
                <Navbar />
                <Terms />
              </div>
              <Footer />
            </div>} />
            <Route path="/privacy" element={<div>
              <div className="gradient__bg">
                <Navbar />
                <Privacy />
              </div>
              <Footer />
            </div>} />
        </Routes>
      </Router>
    </NavbarContextProvider>
  );
}

export default App;
