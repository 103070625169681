import React, { useEffect, useRef, useState } from 'react';
import './feedsimulator.css';

import video1 from '../../assets/feed/feed1.mp4';
import video2 from '../../assets/feed/feed2.mp4';
import video3 from '../../assets/feed/feed3.mp4';
import video4 from '../../assets/feed/feed4.mp4';
import video5 from '../../assets/feed/feed5.mp4';
import video6 from '../../assets/feed/feed6.mp4';
import video7 from '../../assets/feed/feed7.mp4';
import video8 from '../../assets/feed/feed8.mp4';
import video9 from '../../assets/feed/feed9.mp4';
import video10 from '../../assets/feed/feed10.mp4';
import video11 from '../../assets/feed/feed11.mp4';
import video12 from '../../assets/feed/feed12.mp4';
import video13 from '../../assets/feed/feed13.mp4';
import video14 from '../../assets/feed/feed14.mp4';
import video15 from '../../assets/feed/feed15.mp4';
import video16 from '../../assets/feed/feed16.mp4';
import video17 from '../../assets/feed/feed17.mp4';
import video18 from '../../assets/feed/feed18.mp4';

import Sidebar from '../../assets/feed/feedSidebar.svg'
import Navbar from '../../assets/feed/feedNavbar.svg'
import Pin from '../../assets/pin.svg'
import ThreeDot from '../../assets/threedot.svg'
import Cc from '../../assets/cc.svg'
import {Profile} from '../../components/Profile.js'
const VIDEOS = [
  video12, video13, video14, video15, video16, video17, video18,
  video1, video2, video3, video4, video5, video6, video7, video8, video9, video10, video11
];

const FeedSimulator = () => {
  const [isMuted, setIsMuted] = useState(true);
  const [videoLoadStatus, setVideoLoadStatus] = useState(VIDEOS.map(() => false));
  const videoRefs = useRef([]);

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  const togglePlayPause = (videoRef, index) => {
    if (videoRef.paused) {
      videoRef.play();
    } else {
      videoRef.pause();
    }
  };
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (entry.target.readyState >= 2) {
              entry.target.play();
            } else {
              entry.target.addEventListener('loadeddata', () => {
                entry.target.play();
              }, { once: true });
            }
          } else {
            entry.target.pause();
          }
        });
      },
      { threshold: 0.1 }
    );

    videoRefs.current.forEach((video) => {
      observer.observe(video);
    });

    return () => {
        observer.disconnect(); // Properly disconnect the observer
    };
  }, []);

  const handleVideoLoad = (index) => {
    setVideoLoadStatus((prevStatus) => {
      const newStatus = [...prevStatus];
      newStatus[index] = true;
      return newStatus;
    });
  };

  const NAMES = ['Mira', 'Mira', 'Mira', 'Mira', 'Zane', 'Zane', 'Zane', 'Zeena', 'Zeena', 'Zeena', 'Zeena', 'Zeena', 'Zeena', 'Nina',  'Nina', 'Nina', 'Julia', 'Sam']
  const LOCATIONS = ['San Francisco','San Francisco', 'Menlo Park', 'Mountain View', 'San Diego', 'Stanford', 'Stanford', 'New York City', 'San Diego', 'San Carlos', 'Austin', 'Nashville', 'Charlotte', 'Venice', 'Venice', 'Santa Cruz', 'Stanford', 'Portland', ]
  const TIMES = ['2h', '3h', '14h', 'just now', '7h', '5h', '1h', '11h', '2h', '3h', '14h', 'just now', '7h', '5h', '1h', '11h', '2h', '3h', '14h', 'just now', '7h', '5h', '1h', '11h']

  return (
    <>
      <div className="iphone-container fc">
        <div style={{ display: 'block', position: 'absolute', zIndex: 99, right: 10, top: '35%' }}>
          <img src={Sidebar} alt="sidebar" className="feedSidebar" />
        </div>
        <div style={{ display: 'block', position: 'absolute', zIndex: 99, bottom: 15, alignSelf: 'center' }}>
          <img src={Navbar} alt="navbar" className="feedNavbar" />
        </div>
        <div className="feed-container">
          {VIDEOS.map((video, index) => (
            <>
              <video
                ref={(el) => { videoRefs.current[index] = el; }}
                key={index}
                data-index={index}
                src={video}
                playsInline
                muted={isMuted}
                onClick={() => togglePlayPause(videoRefs.current[index], index)}
                style={{ display: videoLoadStatus[index] ? 'block' : 'none' }}
                onLoadedData={() => handleVideoLoad(index)}
              />
              <div className="feed-video-overlay fr sb ac" style={{ position: 'relative', height: 35, bottom: 100, left: 20, marginBottom: -35}}>
                <div className="fr ac">
                  <div className="feed-video-overlay-profile-pic">
                    <Profile />
                  </div>
                  <div className="feed-video-overlay-text fc" style={{marginLeft: 8}}>
                    <h4 style={{fontSize: 12, fontWeight: '600', color: 'white', marginRight: 4}}>{NAMES[index]}</h4>
                    <div className="fr">
                      <p style={{fontSize: 12, fontWeight: '400', color: 'white', marginRight: 4}}>{TIMES[index]}</p>
                      <img src={Pin} alt="pin" style={{width: 15, height: 15, marginRight: 4}}/>
                      <h3 style={{fontSize: 12, fontWeight: '400', color: 'white', marginRight: 4}} >{LOCATIONS[index]}</h3>
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
            </>
          ))}
        </div>
      </div>
      <button onClick={toggleMute} className="feedSoundButton">
        {isMuted ? 'Allow Sound' : 'Mute Videos'}
      </button>
    </>
  );
};

export default FeedSimulator;