import React, { useEffect, useRef, useState } from 'react';
import './hero.css';
import AndroidWaitlistBtn from '../../assets/android-waitlist-btn.svg';
import AppStoreBadge from '../../assets/app-store-button.svg';
import ButterflyWhite from '../../assets/butterfly-white.svg';
import { FeedSimulator } from '../../components';
import { useNavbarContext } from '../../contexts/NavbarContext';
type Props = {}

const Hero = (props: Props) => {
    const {activePage, setActivePage} = useNavbarContext();
    useEffect(() => {
        if (activePage !== "Home") setActivePage("Home")
    }, [])
    
  return (
    <div className="fr hero__container section__padding">
        <div className="fc ac hero__left-col__container">
            <div className="fr ac hero__main-text-container">
                <img src={ButterflyWhite} alt="butterfly-icon" />
                <h1>candid</h1>
            </div>
            <div className="fc hero__subtext">
                <h4>rediscover social</h4>
                <div className="fr ac hero__btns-container">
                    <a href='https://apps.apple.com/us/app/candid-rediscover-social/id6447771018'>
                        <img src={AppStoreBadge} alt="app-store-badge" />
                    </a>
                    <a href="https://455jifk1uky.typeform.com/to/i8qZYdPJ" style={{padding: 8, borderWidth: 2, borderRadius: 5, borderColor: '#eee', borderStyle: 'solid'}}>
                        <h1 style={{fontSize: 14, color: 'white', fontWeight: '600'}}>Get on the waitlist</h1>
                    </a>
                </div>
            </div>
        </div>
        <div className="fc ac hero__right-col__container">
            <FeedSimulator />
        </div>
    </div>
  )
}

export default Hero;
