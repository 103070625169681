export const Profile = (props) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 235 235"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ maskType: "alpha" }} // Correct usage of inline style
  >
    <circle cx="117.5" cy="117.5" r="117.5" fill="#CDCDCD" />
    <mask
      id="a"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="235"
      height="235"
    >
      <circle cx="117.5" cy="117.5" r="117.5" fill="#CDCDCD" />
    </mask>
    <g mask="url(#a)" fill="gray">
      <circle cx="118" cy="93" r="51" />
      <circle cx="118" cy="250" r="100" />
    </g>
  </svg>
);
